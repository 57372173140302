import Swiper from 'swiper';
import { debounce } from 'lodash';
import { getScreenSizeType } from './util';

const uspSwiperSlides = document.querySelectorAll('.js-usp-bar-slider .swiper-slide');
let uspTopBarSwiper = null;

const UspTopBarSlider = (() => {
    const BREAKPOINT_TABLET = 768;
    const numberOfUspSlides = uspSwiperSlides.length;

    function handleSwiper() {
        uspTopBarSwiper = new Swiper('.js-usp-bar-slider', {
            breakpointsInverse: true,
            slidesPerView: 1,
            loop: true,
            speed: 700,
            watchOverflow: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            breakpoints: {
                [BREAKPOINT_TABLET]: {
                    slidesPerView: 3,
                },
            },
        });
    }
    return {
        init() {
            const isDesktop = getScreenSizeType().indexOf('desktop') > -1;
            const isMobile = getScreenSizeType().indexOf('mobile-pt') > -1;
            const isTablet = !isMobile && !isDesktop;

            if (uspTopBarSwiper || isDesktop || (isTablet && numberOfUspSlides < 4) || numberOfUspSlides === 1) return;
            handleSwiper();
        },
        destroySwiper() {
            if (uspTopBarSwiper !== null) {
                uspTopBarSwiper.destroy(true, true);
                uspTopBarSwiper = null;
            }
        },
    };
})();

let windowWidth = $(window).width();

export default (() => {
    if (!uspSwiperSlides) return;

    $(window).on('load resize', debounce((e) => {
        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if (e.type === 'load' || $(window).width() !== windowWidth) {
            // Update the window width for next time
            windowWidth = $(window).width();
            if (getScreenSizeType().indexOf('desktop') > -1) {
                UspTopBarSlider.destroySwiper();
            } else {
                UspTopBarSlider.init();
            }
        }
    }, 300));
})();
